import(/* webpackMode: "eager" */ "/app/app/_assets/decorations/page-not-fox.svg");
;
import(/* webpackMode: "eager" */ "/app/app/_assets/decorations/pawprint-trail-vertical.svg");
;
import(/* webpackMode: "eager" */ "/app/app/_assets/decorations/pawprint-trail.svg");
;
import(/* webpackMode: "eager" */ "/app/app/_assets/illustrations/dotnet-resume.svg");
;
import(/* webpackMode: "eager" */ "/app/app/_assets/illustrations/footer.svg");
;
import(/* webpackMode: "eager" */ "/app/app/_assets/illustrations/fullstack-resume.svg");
;
import(/* webpackMode: "eager" */ "/app/app/_assets/illustrations/home-decor.svg");
;
import(/* webpackMode: "eager" */ "/app/app/_assets/illustrations/its_me.webp");
;
import(/* webpackMode: "eager" */ "/app/app/_assets/illustrations/me_kvant.webp");
;
import(/* webpackMode: "eager" */ "/app/app/_assets/illustrations/next-logo.svg");
;
import(/* webpackMode: "eager" */ "/app/app/_assets/illustrations/not-found.svg");
;
import(/* webpackMode: "eager" */ "/app/app/_assets/illustrations/pet-projects.svg");
;
import(/* webpackMode: "eager" */ "/app/app/_assets/illustrations/react-resume.svg");
;
import(/* webpackMode: "eager" */ "/app/app/_components/AlertMessage.module.scss");
;
import(/* webpackMode: "eager" */ "/app/app/_data/FrontSection.module.scss");
;
import(/* webpackMode: "eager" */ "/app/app/_page_sections/AboutSection.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/_page_sections/ContactSection.tsx");
;
import(/* webpackMode: "eager" */ "/app/app/_page_sections/ExperienceSection.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/_page_sections/ProjectsSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/_page_sections/SkillsSection.tsx");
;
import(/* webpackMode: "eager" */ "/app/app/page.module.scss");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@fluentui/react-icons/lib/icons/chunk-0.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@fluentui/react-icons/lib/icons/chunk-1.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@fluentui/react-icons/lib/icons/chunk-2.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@fluentui/react-icons/lib/icons/chunk-3.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@fluentui/react-icons/lib/icons/chunk-4.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@fluentui/react-icons/lib/icons/chunk-5.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@fluentui/react-icons/lib/sizedIcons/chunk-0.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@fluentui/react-icons/lib/sizedIcons/chunk-1.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@fluentui/react-icons/lib/sizedIcons/chunk-10.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@fluentui/react-icons/lib/sizedIcons/chunk-11.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@fluentui/react-icons/lib/sizedIcons/chunk-12.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@fluentui/react-icons/lib/sizedIcons/chunk-13.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@fluentui/react-icons/lib/sizedIcons/chunk-14.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@fluentui/react-icons/lib/sizedIcons/chunk-15.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@fluentui/react-icons/lib/sizedIcons/chunk-16.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@fluentui/react-icons/lib/sizedIcons/chunk-17.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@fluentui/react-icons/lib/sizedIcons/chunk-2.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@fluentui/react-icons/lib/sizedIcons/chunk-3.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@fluentui/react-icons/lib/sizedIcons/chunk-4.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@fluentui/react-icons/lib/sizedIcons/chunk-5.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@fluentui/react-icons/lib/sizedIcons/chunk-6.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@fluentui/react-icons/lib/sizedIcons/chunk-7.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@fluentui/react-icons/lib/sizedIcons/chunk-8.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@fluentui/react-icons/lib/sizedIcons/chunk-9.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
